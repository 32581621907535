import { useEffect, useState } from 'react';
import { getAPIRoot } from '../helpers/fetch';
import {
  getMappedFilterFutureDates,
  getMonthFilters,
  getOpenedFilters,
} from '../helpers/filters';

const IF_EVENT_ID = 1001;

// adapt current filters to what backend expects
const prepareFiltersForAPI = (filters) => {
  const filtersForAPI = {
    categories:
      filters?.eventType.filter((typeId) => typeId !== IF_EVENT_ID) || [],
    ifEvent: filters?.eventType.includes(IF_EVENT_ID) ?? false,
    ...getMappedFilterFutureDates(filters?.time?.[0]),
    regions: filters?.region || [],
    find: filters?.find || '',
  };

  return JSON.stringify(filtersForAPI);
};

export const useEventFilters = (filters) => {
  const [totalCount, setTotalCount] = useState(null);
  const [apiFilters, setApiFilters] = useState(null);
  const [visibleFilters, setVisibleFilters] = useState(null);
  const [page, setPage] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noMoreResults, setNoMoreResults] = useState(true);

  // get filters from backend when component loads
  useEffect(() => {
    getAPIFilters();
  }, []);

  // initialize visible filters after receiving possible filters from backend
  // change them if filters changed
  useEffect(() => {
    if (apiFilters) {
      setVisibleFilters({
        ...apiFilters,
      });
    }
  }, [apiFilters, filters]);

  // update results once we have calculated what filters should be visible
  useEffect(() => {
    if (visibleFilters && filters) {
      updateResults();
    }
  }, [visibleFilters]);

  // update results on page change
  useEffect(() => {
    if (page > 0) {
      updateResults();
    }
  }, [page]);

  const increasePage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getFiltersThatShouldApply = () => {
    // get only filters that are visible
    return Object.fromEntries(
      Object.entries(filters).map(([key, appliedFilter]) => {
        if (Array.isArray(appliedFilter)) {
          return [
            key,
            appliedFilter.filter((appliedFilterItem) =>
              visibleFilters[key].find(
                (visibleFilter) => visibleFilter.id === appliedFilterItem
              )
            ),
          ];
        } else {
          return [key, appliedFilter];
        }
      })
    );
  };

  const getFilteredResultsJsonResponse = async () => {
    let apiRoot = await getAPIRoot();

    const filtersThatShouldApply = getFiltersThatShouldApply();

    const payload = prepareFiltersForAPI(filtersThatShouldApply);
    const url = `${apiRoot}${process.env.GATSBY_API_EVENTS}/${page}/${filters?.sort}`;
    const options = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
    };

    const response = await fetch(url, options);
    return response.json();
  };

  // get filters from backend, rename them and set state
  const getAPIFilters = async () => {
    let apiRoot = await getAPIRoot();
    const url = apiRoot + process.env.GATSBY_API_EVENTS_FILTERS;

    const response = await fetch(url);
    const _apiFilters = await response.json();

    setApiFilters({
      eventType: [
        {
          id: IF_EVENT_ID,
          name: 'iF Event',
        },
        ..._apiFilters['categories'],
      ],
      region: _apiFilters['regions'],
      time: getMonthFilters('event'),
    });
  };

  const updateResults = async () => {
    setLoading(true);

    const json = await getFilteredResultsJsonResponse();

    setTotalCount(json.count);

    if (!json.items.length) {
      setNoMoreResults(true);
    }

    if (page > 0) {
      const _results = results.concat(json.items);
      if (_results.length === json.count) {
        setNoMoreResults(true);
      }
      setResults(_results);
    } else {
      setResults(json.items);
      setNoMoreResults(json.items.length === json.count);
    }

    setLoading(false);
  };

  return {
    filterOrderByKey: ['time', 'region', 'eventType'],
    visibleFilters,
    openedFilters: getOpenedFilters(filters, ['region', 'time', 'eventType']),
    radioFiltersKeys: ['time'],
    increasePage,
    results,
    loading,
    noMoreResults,
    totalCount,
  };
};
